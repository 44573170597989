import React, { useState } from 'react';

import Button from '../../library/button/button';
import FitmentNotesModal from './components/fitment-notes-modal/fitment-notes-modal';

import { trackAction } from '../../shared/utils/analytics/analytics';

import { Fitment } from '../../shared/types/fitment';

interface FitmentNotesButtonProps {
  selectedFitment?: Fitment | null | undefined;
}

function FitmentNotesButton(props: FitmentNotesButtonProps) {
  const { selectedFitment } = props;
  const { isGeneric } = selectedFitment || {};

  const [isFitmentNotesOpen, setIsFitmentNotesOpen] = useState(false);
  function toggleFitmentNotes() {
    setIsFitmentNotesOpen(!isFitmentNotesOpen);
  }
  if (!selectedFitment || isGeneric) {
    return null;
  }

  return (
    <>
      {selectedFitment?.vehicleAssemblyId && (
        <Button
          onClick={() => {
            toggleFitmentNotes();
            trackAction('vehicle_fitment_notes_modal_open');
          }}
        >
          View vehicle fitment notes
        </Button>
      )}
      <FitmentNotesModal
        isOpen={isFitmentNotesOpen}
        onClose={() => {
          toggleFitmentNotes();
          trackAction('vehicle_fitment_notes_modal_close');
        }}
        selectedFitment={selectedFitment}
      />
    </>
  );
}

export default FitmentNotesButton;
