import React from 'react';

import LibraryVehicleDescription from '../../library/vehicle-description/vehicle-description';
import FitmentNotesButton from '../fitment-notes-button/fitment-notes-button';

import { shouldShowGenericCategory } from '../../shared/utils/fitment';

import { Fitment } from '../../shared/types/fitment';
import { Maybe } from '../../shared/types/graphql-types';

import './vehicle-description.scss';

interface VehicleDescriptionProps {
  make?: Maybe<string>;
  model?: Maybe<string>;
  renderAfterYearMake?: () => JSX.Element;
  rideHeight?: Maybe<string>;
  selectedFitment?: Fitment | null | undefined;
  showFitmentNotes?: boolean; //
  trim?: Maybe<string>;
  vehicleCategory?: string; //
  vehicleSubCategory?: string; //
  year?: Maybe<string>;
}

function VehicleDescription(props: VehicleDescriptionProps) {
  const {
    make: propsMake,
    model: propsModel,
    renderAfterYearMake,
    rideHeight,
    selectedFitment,
    showFitmentNotes,
    trim: propsTrim,
    vehicleCategory,
    vehicleSubCategory,
    year: propsYear
  } = props;
  let year = propsYear;
  let make = propsMake;
  let model = propsModel;
  let trim = propsTrim;

  if (shouldShowGenericCategory(props)) {
    year = vehicleCategory;
    make = '';
    model = vehicleSubCategory;
    trim = '';
  }

  return (
    <>
      <LibraryVehicleDescription
        make={make || ''}
        model={model || ''}
        renderAfterYearMake={renderAfterYearMake}
        rideHeight={rideHeight || ''}
        trim={trim || ''}
        year={year || ''}
      />
      {showFitmentNotes && selectedFitment && (
        <div styleName="fitment-notes-container">
          <FitmentNotesButton selectedFitment={selectedFitment} />
        </div>
      )}
    </>
  );
}

export default VehicleDescription;
